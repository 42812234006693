import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithEmailLink } from '@firebase/auth';
import { verify } from 'jsonwebtoken';
import {
  ERROR_AUTH_BAD_TOKEN,
  ERROR_AUTH_NO_TOKEN
} from '@apprentage/constants';
import { withFirebase } from '../../Firebase';
import { setUser } from '../../../actions';
import { SLASH } from '../../../constants/routes';
import { setUserLoggedIn } from '../../../services/organizations';
import { redirectToAuth } from '../../../services/auth';
import Loading from '../../Loading';
import convertUrlParamsToObject from '../../../utils/convertUrlParamsToObject';
import { app } from '../../../firebaseConfig';

class SSO extends Component {
  componentDidMount() {
    const {
      location: { search: locationSearch }
    } = this.props;

    const localToken = window.localStorage.getItem('turbine_token');

    if (!localToken && locationSearch && !locationSearch.includes('token')) {
      redirectToAuth({
        error: {
          code: ERROR_AUTH_NO_TOKEN
        }
      });
    }

    const { token: urlToken } = convertUrlParamsToObject(locationSearch);
    const token = urlToken || localToken;

    verify(token, process.env.REACT_APP_AUTH_TOKEN_SECRET, (err, decoded) => {
      if (err) {
        redirectToAuth({
          error: {
            code: ERROR_AUTH_BAD_TOKEN,
            message: err.message
          }
        });
        return null;
      }

      const {
        actionCode, emailLink, email, password
      } = decoded;
      const auth = getAuth(app);

      console.log('LMS decoded:', decoded);

      if (actionCode && email && emailLink) {
        signInWithEmailLink(auth, email, emailLink)
          .then(({ user }) => {
            console.log('LMS user:', user);
            // Clear email from storage.
            window.localStorage.removeItem('emailForSignIn');
            setUserLoggedIn();
            setUser(user);
            window.localStorage.setItem('turbine_token', token);

            // TODO investigate lms-admin and why there is additional logic here
            // around continueUrl. Is this needed here as well?
          })
          .catch(({ message, code }) => {
            redirectToAuth({
              error: {
                code,
                message
              }
            });
          });
        return;
      }

      signInWithEmailAndPassword(auth, email, password)
        .then(({ user }) => {
          setUserLoggedIn();
          setUser(user);
          window.localStorage.setItem('turbine_token', token);

          // TODO investigate lms-admin and why there is additional logic here
          // around continueUrl. Is this needed here as well?
        })
        .catch(({ message, code }) => {
          redirectToAuth({
            error: {
              code,
              message
            }
          });
        });
    });
  }

  render() {
    const {
      user,
      location: { search: locationSearch }
    } = this.props;
    const { continueUrl } = convertUrlParamsToObject(locationSearch);

    if (user) {
      // TODO handle deeplink after redirect
      if (continueUrl) {
        window.location = continueUrl;

        return;
      }

      return <Redirect to={SLASH} />;
    }

    return <Loading />;
  }
}

const mapStateToProps = ({ user }) => ({ user });

export default withFirebase(connect(mapStateToProps, { setUser })(SSO));
